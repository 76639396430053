import { useMasters } from "./masters";
import { defineStore } from "pinia";
import { reactive } from "vue";

let alertId = 0;

export const errorData = {
  type: "error",
  icon: "mdi-alert-circle",
  iconColor: "brand-lighten-3",
  borderColor: "error",
};

export const successData = {
  type: "success",
  icon: "mdi-check-circle",
  iconColor: "success",
  borderColor: "success",
};

export const warningData = {
  type: "warning",
  icon: "mdi-alert",
  iconColor: "warning",
  borderColor: "warning",
};

export const infoData = {
  type: "info",
  icon: "mdi-information",
  iconColor: "light-blue-lighten-1",
  borderColor: "light-blue-lighten-1",
};

export const useWarningStore = defineStore("globalMessage", {
  state: () => ({
    alerts: reactive([]),
    info: {
      show: false,
      text: "",
    },
  }),

  actions: {
    showAlert(data) {
      if (this.alreadyWarned(data.message)) {
        console.log("Ya notificado", data.message);
        return;
      }
      const id = alertId++;
      this.alerts.push({
        id,
        ...data,
        visible: true,
      });
    },

    hideAlert(id) {
      const index = this.alerts.findIndex((alert) => alert.id === id);
      if (index !== -1) {
        this.alerts.splice(index, 1);
      }
    },

    showGenericErrorMessage(messageText, timeout = false) {
      const data = {
        ...errorData,
        message: messageText,
        timeout: timeout ? 600000 : -1,
      };
      this.showAlert(data);
    },

    showGenericSuccessMessage(messageText, timeout = true) {
      const data = {
        ...successData,
        message: messageText,
        timeout: timeout ? 6000 : -1,
      };
      this.showAlert(data);
    },

    showGenericWarningMessage(messageText, timeout = false) {
      const data = {
        ...warningData,
        message: messageText,
        timeout: timeout ? 60000 : -1,
      };
      this.showAlert(data);
    },

    showGenericInfoMessage(messageText, timeout = false) {
      const data = {
        ...infoData,
        message: messageText,
        timeout: timeout ? 600000 : -1,
      };
      this.showAlert(data);
    },
    cleanInfoMessage() {
      const masters = useMasters();
      masters.setGlobalLoading(false);
      this.info.show = false;
      this.info.text = "";
    },
    setInfoMessage(messageText) {
      const masters = useMasters();
      masters.setGlobalLoading(true);
      this.info.show = true;
      this.info.text = messageText;
    },
  },
  getters: {
    alreadyWarned: (state) => (message) => {
      // Lógica para verificar si el mensaje ya fue mostrado
      return state.alerts.some((alert) => alert.message === message);
    },
  },
});
