import { useCalendar } from "~/store/calendar";
import { useMasters } from "~/store/masters";

export const formatDate = (date, format = null, separator = "/") => {
  const nuxtApp = useNuxtApp();

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const formated_day = day < 10 ? "0" + day : day;
  const formated_month = month < 10 ? "0" + month : month;
  const formated_hours = hours > 0 && hours < 10 ? "0" + hours : hours;
  const formated_minutes =
    minutes > 0 && minutes < 10 ? "0" + minutes : minutes;
  const formated_seconds =
    seconds > 0 && seconds < 10 ? "0" + seconds : seconds;

  if (format) {
    switch (format) {
      case "day":
        return formated_day;
      case "month":
        return formated_month;
      case "monthName":
        return nuxtApp.$i18n.t(`common.calendar.months.${month}`);
      case "year":
        return year;
      case "complete_es":
        return `${formated_day}/${formated_month}/${year} ${formated_hours}:${formated_minutes}:${formated_seconds}`;
      case "complete_en":
        return `${formated_month}/${formated_day}/${year} ${formated_hours}:${formated_minutes}:${formated_seconds}`;
      case "DMY":
        return `${formated_day}${separator}${formated_month}${separator}${year}`;
      case "MDY":
        return `${formated_month}${separator}${formated_day}${separator}${year}`;
      case "YMD":
        return `${year}${separator}${formated_month}${separator}${formated_day}`;
      case "iso":
        return date.toISOString().slice(0, 10);
    }
  }

  return nuxtApp.$i18n.locale.value === "es"
    ? `${formated_day}/${formated_month}/${year}`
    : `${formated_month}/${formated_day}/${year}`;
};

/**
 * Obtiene el número de año pasada una fecha
 * @param {Date} date
 */
export const getWeek = (date) => {
  const copiedDate = new Date(date.getTime());

  // Configurar el día de la semana a lunes. Puedes ajustar esto según tus necesidades.
  copiedDate.setDate(
    copiedDate.getDate() + 3 - ((copiedDate.getDay() + 6) % 7)
  );

  // Obtener el tiempo en milisegundos del primer jueves de enero
  const firstDay = copiedDate.valueOf();

  // Configurar la fecha al 1 de enero
  copiedDate.setMonth(0, 1);

  // Calcular la semana del año
  return Math.ceil((firstDay - copiedDate) / 604800000);
};

/**
 * Confirma si la fecha está entre la semana actual y la siguiente
 * @param {String} date
 * @returns
 */
export const isCurrentOrNextWeek = (date) => {
  const start_range = getStartOfWeek(new Date());
  const end_range = addDays(start_range, 13);

  return (
    normalizeDate(new Date(date)) >= normalizeDate(start_range) &&
    normalizeDate(new Date(date)) <= normalizeDate(end_range)
  );
};

/**
 * Confirma si la fecha está en la semana actual
 * @param {String} date
 * @returns
 */
export const isCurrentWeek = (date) => {
  const start_range = getStartOfWeek(new Date());
  const end_range = addDays(start_range, 6);

  return (
    normalizeDate(new Date(date)) >= normalizeDate(start_range) &&
    normalizeDate(new Date(date)) <= normalizeDate(end_range)
  );
};

/**
 * Confirma si la fecha está en la semana actual
 * @param {String} date
 * @returns
 */
export const isCurrentWeekOrBefore = (date) => {
  const sunday = addDays(getStartOfWeek(new Date()), 6);

  return normalizeDate(new Date(date)) <= normalizeDate(sunday);
};

/**
 * Confirma si la fecha está en la semana actual
 * @param {String} date
 * @returns
 */
export const isBeforeToday = (date) => {
  const today = new Date();

  return normalizeDate(new Date(date)) < normalizeDate(today);
};

/**
 *  Normalizar las fechas a medianoche (sin horas)
 * @param {String} date
 * @returns
 */
export const normalizeDate = (date) =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate());

/**
 * Adaptación del metodo getDay() para ambos idiomas
 * @param {Date} date
 */
export const getDateDay = (date) => {
  return (date.getDay() + 6) % 7;
};

/**
 * Añade/Resta días a una fecha
 * @param {Date} date
 * @param {Number} days
 */
export const addDays = (date, days) => {
  let new_date = new Date(date);
  new_date = new Date(new_date.setDate(new_date.getDate() + days));
  return new_date;
};

/**
 * Devuelve el inicio de semana de una fecha (lunes)
 * @param {Date} date
 * @returns
 */
export const getStartOfWeek = (date) => {
  const dateTmp = new Date(date);
  const dayOfWeek = dateTmp.getDay();
  const diff = dateTmp.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
  // Hora actual
  let actualHour = new Date();
  actualHour = actualHour.getHours();
  const startDay = new Date(dateTmp.setDate(diff));
  startDay.setHours(actualHour);

  return startDay;
};

/**
 * Devuelve el final de la semana de una fecha (domingo)
 * @param {Object} date
 * @returns
 */
export const getEndOfWeek = (date) => {
  const endOfWeek = getStartOfWeek(date); // Crear una copia de la fecha de inicio de semana
  endOfWeek.setDate(endOfWeek.getDate() + 6); // Sumar 6 días para obtener el final de la semana
  // Comentado por error con fechas UTC server linux
  // endOfWeek.setHours(23, 59, 59, 999); // Establecer la hora a 23:59:59
  return new Date(endOfWeek);
};

/**
 * Obtiene un array con los días de la semana y su nombre
 * @param {Object} weekStartDate
 * @returns Array
 */
export const setWeekDays = (weekStartDate) => {
  const nuxtApp = useNuxtApp();
  let week_dates = [];

  if (weekStartDate) {
    for (let i = 0; i < 7; i++) {
      const date = addDays(weekStartDate, i);
      week_dates.push({
        name: nuxtApp.$i18n.t(`common.calendar.weekdays.${i}`),
        date: date,
      });
    }
  }
  return week_dates;
};

export const changeFormatMDY = (date, separator = "/") => {
  const partes = date.split(separator);

  const month = partes[1];
  const day = partes[0];
  const year = partes[2];

  return month + separator + day + separator + year;
};

export const changeFormatYMD = (date, separator = "/") => {
  const partes = date.split(separator);

  const month = partes[1];
  const day = partes[0];
  const year = partes[2];

  return year + separator + month + separator + day;
};

export const sameYear = (date1, date2) => {
  const year1 = new Date(date1).getFullYear();
  const year2 = new Date(date2).getFullYear();

  return year1 === year2;
};

export const getDateDMY = (date, separator = "/") => {
  let dateTmp = new Date(date);

  let day = dateTmp.getDate();
  let month = dateTmp.getMonth() + 1;
  const year = dateTmp.getFullYear();

  day = day < 10 ? "0" + day : day;
  month = month < 10 ? "0" + month : month;

  return day + separator + month + separator + year;
};

export const isHoliday = (date) => {
  const calendar = useCalendar();
  const holidays = calendar.getHolidaysWeek;

  const fechaFormateada = getDateDMY(date);
  if (!holidays) return false;

  for (const holiday of holidays) {
    if (fechaFormateada === holiday.FESTIVO) {
      return true;
    }
  }

  return false;
};

export const isMinorDate = (date) => {
  const today = new Date();
  return new Date(date) < today;
};

export const weekDayNames = [
  { "es-ES": "Lu.", "en-US": "Mon." },
  { "es-ES": "Ma.", "en-US": "Tues." },
  { "es-ES": "Mi.", "en-US": "Wed." },
  { "es-ES": "Ju.", "en-US": "Thurs." },
  { "es-ES": "Vi.", "en-US": "Fri." },
  { "es-ES": "Sa.", "en-US": "Sat." },
  { "es-ES": "Do.", "en-US": "Sun." },
];

export const getDatesBetweenTwoDates = (init, end, weekDays) => {
  const datesList = [];
  let actualDate = new Date(init);

  while (actualDate < end) {
    if (weekDays.includes(getDateDay(actualDate))) {
      datesList.push(new Date(actualDate));
    }
    actualDate = addDays(actualDate, 1);
  }

  if (weekDays.includes(getDateDay(end))) {
    datesList.push(new Date(end));
  }

  return datesList;
};

export const getHolidaysBetweenDates = (dates) => {
  const masters = useMasters();
  const holidaysPerWeek = masters.getHolidaysPerWeek;
  const holidays = [];

  for (const date in dates) {
    const week = getWeek(dates[date]);
    const dateFormat = getDateDMY(dates[date], "/");
    // No guardo el formato nuevo para solo cambiarlo a la hora de mostrar
    for (const holiday in holidaysPerWeek[week]) {
      if (
        dateFormat === holidaysPerWeek[week][holiday].FESTIVO &&
        !holidays.includes(dates[date])
      ) {
        holidays.push(dates[date]);
      }
    }
  }

  return holidays;
};

/* 
Obtener el nombre del festivo
*/
export const getHolidaysName = (date, week, typeHtml = true) => {
  const { t } = useI18n();
  const nuxtApp = useNuxtApp();
  const masters = useMasters();
  const holidays = getHolidaysPerDay(date, week);
  let notes = {};
  for (const holiday of holidays) {
    if (!notes.hasOwnProperty(holiday.NOTES) && holiday.NOTES !== null) {
      notes[holiday.NOTES] = [];
    }

    const country = masters.machines?.countries[holiday.CALENDARIO];
    const site = masters.machines?.sites[holiday.CALENDARIO];
    const holiday_location = country
      ? nuxtApp.$i18n.locale.value === "es"
        ? country.COUNTRYDESC_ES
        : country.COUNTRYDESC_EN
      : `${holiday.CALENDARIO} - ${site?.SITEDESC}`;

    if (holiday.NOTES) {
      notes[holiday.NOTES].push(holiday_location);
    } else {
      if (!notes.hasOwnProperty(t("common.calendar.holiday"))) {
        notes[t("common.calendar.holiday")] = [holiday_location];
      } else {
        notes[t("common.calendar.holiday")].push(holiday_location);
      }
    }
  }
  if (typeHtml) {
    const result = Object.keys(notes).map(
      (note) => `<p><strong>${note}</strong></p> ${notes[note].join("<br/> ")}`
    );
    return result.length
      ? result.join("<br/><br/>")
      : nuxtApp.$i18n.t("common.calendar.holiday");
  } else {
    const result = Object.keys(notes).map((note) => `${note}`);
    return result.length ? result : nuxtApp.$i18n.t("common.calendar.holiday");
  }
};

export const getHolidaysPerDay = (date, week) => {
  const masters = useMasters();
  const holidaysPerWeek = masters.getHolidaysPerWeek;
  const fechaFormateada = getDateDMY(date);
  const arrayHolidays = [];

  for (const holiday of holidaysPerWeek[week]) {
    if (fechaFormateada === holiday.FESTIVO) {
      arrayHolidays.push(holiday);
    }
  }

  return arrayHolidays;
};

/**
 * Transforma un valor decimal en texto XXh XXmin
 */
export const parseHoursToText = (hours) => {
  const { $i18n } = useNuxtApp();

  const horas = Math.floor(hours);
  const minutos = Math.round((hours - horas) * 60);

  // const minutosFormatted =
  //   minutos > 0 && minutos < 10 ? `0${minutos}` : minutos;

  if (horas == 0 && minutos == 0) {
    return `${horas} ${$i18n.t("common.fields.hours.abbr")}`;
  }

  let result = "";

  if (horas > 0) {
    result = `${horas} ${$i18n.t("common.fields.hours.abbr")}`;
  }

  if (minutos > 0) {
    result += ` ${minutos} ${$i18n.t("common.fields.minutes.abbr")}`;
  }

  return result;
};

export const isLaborableDay = (laborableWeek, day) => {
  if (laborableWeek == "7") return true;

  if (laborableWeek == "6" && day == "7") return false;

  if (laborableWeek == "5" && (day == "6" || day == "7")) return false;

  return true;
};

export const isSameDay = (date1, date2) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const getDateYMD = (date, separator = "/") => {
  let dateTmp = new Date(date);

  let day = dateTmp.getDate();
  let month = dateTmp.getMonth() + 1;
  const year = dateTmp.getFullYear();

  day = day < 10 ? "0" + day : day;
  month = month < 10 ? "0" + month : month;

  return year + separator + month + separator + day;
};

export const timeToDecimal = (time) => {
  const [hours, minutes] = time.split(":").map(Number);

  const decimalHours = hours + minutes / 60;

  return decimalHours.toFixed(2);
};

export const decimalToTime = (decimal) => {
  const hours = Math.floor(decimal);
  const minutes = Math.round((decimal - hours) * 60);

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
};

/**
 * Compara una fecha con la fecha actual según la operación indicada
 * @param {Date} date - La fecha a comparar
 * @param {'<' | '<=' | '==' | '>=' | '>'} operator - El operador de comparación
 * @returns {boolean} - El resultado de la comparación
 */
export const compareDateWithToday = (date, operator) => {
  // Obtener la fecha actual
  const today = new Date();

  // Crear objetos de fecha con sólo año, mes y día para ambas fechas
  const d1 = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const d2 = new Date(today.getFullYear(), today.getMonth(), today.getDate());

  // Comparar las fechas según el operador
  switch (operator) {
    case "<":
      return d1 < d2;
    case "<=":
      return d1 <= d2;
    case "==":
      return d1.getTime() === d2.getTime(); // comparar timestamps para igualdad exacta
    case ">=":
      return d1 >= d2;
    case ">":
      return d1 > d2;
    default:
      throw new Error("Operador inválido. Use <, <=, ==, >=, o >");
  }
};

/**
 * Comprueba si el día es fin de semana
 * @param {Date} date
 */
export const isWeekend = (date) => {
  const day = date.getDay();
  return day === 0 || day === 6;
};

/* 
  Suma 1 semana a la fecha actual
*/
export const getNextWeek = () => {
  const actualDate = new Date();
  const nextWeekDate = new Date();
  nextWeekDate.setDate(actualDate.getDate() + 7);

  return nextWeekDate;
};
