<template>
  <div class="w-100">
    <v-empty-state text-width="480">
      <template v-slot:media>
        <v-icon :icon="icon" :color="color"> </v-icon>
      </template>

      <template v-slot:title>
        <h2>{{ t(title) }}</h2>
      </template>

      <template v-slot:text>
        <div
          class="empty_state__text mb-4"
          style="max-width: 35ch; white-space: normal"
        >
          {{ t(text) }}
        </div>

        <nuxt-link v-if="redirect" :to="redirect?.link">
          <v-btn
            flat
            class="text-none"
            color="dark"
            v-if="redirect.to"
            variant="outlined"
            prepend-icon="mdi-backburger"
          >
            {{ t(redirect.to) }}
          </v-btn>
        </nuxt-link>
      </template>
    </v-empty-state>
  </div>
</template>
  
  <script setup>
const { t } = useI18n();

const props = defineProps({
  icon: {
    type: String,
    default: "mdi-message-question-outline",
  },
  title: {
    type: String,
    default: "common.messages.empty.title",
  },
  text: {
    type: String,
    default: "common.messages.empty.text",
  },
  redirect: {
    type: Object,
    required: false,
  },
  color: {
    type: String,
    default: "light",
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.empty_state {
  &__text {
    color: $color-dark-500;
  }
}
</style>
  