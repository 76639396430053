import { defineStore } from "pinia";
import { useMasters } from "~/store/masters";
import { useUserStore } from "~/store/auth";
import { useWarningStore } from "~/store/globalMessage";

import WWSS from "~/utils/wwss";

export const useLocationSelector = defineStore("locationSelector", {
  state: () => ({
    visible: false,
    sending: false,
    sendingPercentage: 0,
    parques: [],
  }),
  actions: {
    setVisible(value) {
      this.visible = value;
    },

    setParques(value) {
      this.parques = value;
    },
    async saveLocations() {
      const masters = useMasters();
      const globalMessage = useWarningStore();
      const nuxtApp = useNuxtApp();
      const userStore = useUserStore();
      const route = useRoute();

      let maquinas = [];
      const parques = JSON.parse(JSON.stringify(this.parques));
      const $CHUNK_SIZE = 200;

      // Obtenemos las máquinas seleccionadas
      parques.forEach((parque) => {
        let parque_maquinas = parque.maquinas.map((m) => {
          m.VISUALIZA = m.check ? "1" : "0";
          return m;
        });
        maquinas = maquinas.concat(parque_maquinas);
      });

      // Comprobamos si ha habido cambios en las máquinas asociadas al usuario
      if (!this.selectionHasBeenChanged(maquinas)) {
        globalMessage.showGenericWarningMessage(
          nuxtApp.$i18n.t("locationSelector.modal.noChanges")
        );
      } else {
        // Aviso de tiempo que puede tardar
        masters.setOnCloseConfirm(true);
        masters.setGlobalLoading(true);
        this.sending = true;
        // Dividimos las máquinas en paquetes
        let list_machines_pkg = [];
        let machines_pkg = "";
        let counter_pkg_size = 0;

        // Recorrer todas las máquinas formando paquetes de un número de elementos determinado
        for (let i = 0; i < maquinas.length; i++) {
          machines_pkg +=
            maquinas[i].LOCATION +
            "-" +
            maquinas[i].SITEID +
            "-" +
            maquinas[i].VISUALIZA +
            ",";
          counter_pkg_size = counter_pkg_size + 1;

          // Si se ha completo un paquete de máquinas se añade al array del listado de paquetes
          if (counter_pkg_size === $CHUNK_SIZE) {
            // Eliminar la coma del último elemento
            machines_pkg = machines_pkg.slice(0, machines_pkg.length - 1);
            // Guardar el listado de máquinas en el array de paquetes
            list_machines_pkg.push(machines_pkg);
            // Resetear el contador de máquinas incluidas en un paquete
            counter_pkg_size = 0;
            machines_pkg = "";
          }
        }

        // Incluir el último paquete en caso de que no haya llegado al número de máquinas determinado
        if (counter_pkg_size > 0 && counter_pkg_size < $CHUNK_SIZE) {
          // Eliminar la coma del último elemento
          machines_pkg = machines_pkg.slice(0, machines_pkg.length - 1);
          // Guardar el listado de máquinas en el array de paquetes
          list_machines_pkg.push(machines_pkg);
        }

        const respuestas = [];

        for (const pkg in list_machines_pkg) {
          this.sendingPercentage =
            (parseInt(pkg) / list_machines_pkg.length) * 100;
          respuestas.push(
            await fetchPostData(WWSS.user.machines.set, {
              item: userStore.login?.CodOperarioMaximo,
              last_package:
                list_machines_pkg.length != parseInt(pkg) + 1 ? 0 : 1,
              maquinas: list_machines_pkg[pkg],
            })
          );
        }

        let error = false;
        for (const res in respuestas) {
          if (
            !(
              respuestas[res].response ||
              (Array.isArray(respuestas[res]) && respuestas[res][0].APLICA)
            )
          ) {
            error = true;
          }
        }
        if (error) {
          globalMessage.showGenericErrorMessage(
            nuxtApp.$i18n.t("locationSelector.modal.errorMachines")
          );
        }

        masters.setOnCloseConfirm(false);

        if (route.path == "/" || route.path == "/en") {
          this.sending = false;
          await masters.getAllMachinesWS();
        } else {
          this.sendingPercentage = 100;
          setTimeout(() => {
            window.location.reload();
          }, 400);
          return;
        }
        // Aviso de tiempo que puede tardar
        masters.setGlobalLoading(false);
        this.visible = true;
      }
    },

    selectionHasBeenChanged(machines_modified) {
      const masters = useMasters();
      // Obtenemos las máquinas marcadas con visualiza de los datos originales y los modificados
      let visualizaModified = machines_modified.filter(
        (machine) => machine.VISUALIZA === "1"
      );
      let visualizaOriginal = masters.machines.totalList.filter(
        (machine) => machine.VISUALIZA === "1"
      );

      // Recorremos el array de máquinas modificadas y buscamos si existen en el array de originales
      let machines_changed = false;

      // Si se obtiene el mismo número de máquinas marcadas con visualiza entonces se revisa una a una si han cambiado
      if (visualizaModified.length === visualizaOriginal.length) {
        let i = 0;
        while (i < visualizaModified.length && !machines_changed) {
          if (
            !visualizaOriginal.some(
              (machine) =>
                machine.SITEID === visualizaModified[i].SITEID &&
                machine.LOCATION === visualizaModified[i].LOCATION
            )
          ) {
            machines_changed = true;
          }
          i++;
        }
      } else {
        // Si el número de máquinas con visualiza es distinto al original quiere decir que han cambiado
        machines_changed = true;
      }

      // Devolver el resultado
      return machines_changed;
    },
  },
  getters: {},
});
