import { useCalendar } from "~/store/calendar";
import { useMasters } from "~/store/masters";
import { useGlobalCrews } from "~/store/globalCrews";

import { v4 as uuidv4 } from "uuid";
import WWSS from "~/utils/wwss";

export const DEFAULT_CREW_HOURS = 7.5;
export const DEFAULT_CREW_WORKERS = 2;
export const CREW_NULL = "without";

/* Generar bloques en el calendario para los días anteriores que no se pueden agregar */
export const generateBlockedDays = (formatCrews) => {
  const possibleDays = generatePossibleDays();

  for (const crew in formatCrews) {
    const tmpPossibleDays = [...possibleDays];
    for (const crewDay of formatCrews[crew]) {
      const dateTmp = changeFormatYMD(
        getDateDMY(new Date(crewDay.OPDATE), "-"),
        "-"
      );
      if (tmpPossibleDays.includes(dateTmp)) {
        tmpPossibleDays.splice(tmpPossibleDays.indexOf(dateTmp), 1);
      }
    }
    for (const blockDay of tmpPossibleDays) {
      formatCrews[crew].push({
        AMCREWAVAILAEID: "",
        AMCREWID: "",
        LABORCODE: "",
        ROWSTAMP: "",
        AMCREW: "",
        TECHNICIANS: [],
        OPDATE: blockDay,
        i: uuidv4(),
        x: getDateDay(new Date(blockDay)),
        y: 1,
        w: 1,
        h: 1,
      });
    }
  }
  return formatCrews;
};

const generatePossibleDays = () => {
  const calendar = useCalendar();
  const today = new Date();
  const possibleDays = [];
  for (const day of calendar.weekDaysRange) {
    if (day < today) {
      possibleDays.push(changeFormatYMD(getDateDMY(day, "-"), "-"));
    }
  }
  return possibleDays;
};

export const generateBlockedDragDays = (formatCrews) => {
  const clonFormatCrews = [...formatCrews];
  const possibleDays = generatePossibleDays();
  const tmpPossibleDays = [...possibleDays];

  for (const crewDay in clonFormatCrews) {
    const dateTmp = changeFormatYMD(
      getDateDMY(new Date(clonFormatCrews[crewDay].OPDATE), "-"),
      "-"
    );

    if (tmpPossibleDays.includes(dateTmp)) {
      tmpPossibleDays.splice(tmpPossibleDays.indexOf(dateTmp), 1);
    }
  }

  for (const blockDay of tmpPossibleDays) {
    clonFormatCrews.push({
      AMCREWAVAILAEID: "",
      AMCREWID: "",
      LABORCODE: "",
      ROWSTAMP: "",
      AMCREW: "",
      TECHNICIANS: [],
      OPDATE: blockDay,
      i: "",
      x: getDateDay(new Date(blockDay)),
      y: 1,
      w: 1,
      h: 1,
    });
  }
  return clonFormatCrews;
};

/**
 * Obtiene el nombre del técnico de la asociación ID del técnico con la cuadrilla y día
 * @param {String} data
 */
export const getTechniciansData = (data) => {
  const masters = useMasters();
  let technicians_data = [];
  if (!data) return technicians_data;

  const technicians = data.split(",");
  technicians.forEach((technician) => {
    const technician_data = technician.split("_");

    const mate_data = masters.mates.list.find((m) => {
      return technician_data[0] === m.LABORID;
    });

    if (mate_data) {
      technicians_data.push({
        LABORCODE: technician_data[0],
        AMCREWLABOREID: technician_data[1],
        NAME: `${mate_data.FIRSTNAME} ${mate_data.LASTNAME}`,
      });
    }
  });

  return technicians_data;
};

export const getDataCrew = (id) => {
  const masters = useMasters();

  const extraData = masters.crews.mapId[id];

  return extraData;
};

export const showInputTechnical = (index, workers) => {
  if (index === 0) return true;
  // return workers[index - 1] == undefined ? false : true;

  return (
    !!workers[index - 1] || !!workers.slice(index).find((worker) => worker)
  );
};

// Funcion para lanzar todas las peticiones de tecnicos para un día (Eliminando y creando si ya existe algo asignado)
export const associateTechnical = async (dataAssociation) => {
  const nuxtApp = useNuxtApp();

  const {
    AMCREWAVAILAEID,
    AMCREWID,
    OPDATE,
    TECHNICIANS,
    LABORCODE_AMCREWLABORAEID,
  } = dataAssociation;

  const createAssociation = [];
  const deleteAssociation = [];

  const currentTechnical = LABORCODE_AMCREWLABORAEID?.split(",") ?? [];

  for (const technical in TECHNICIANS) {
    if (
      (LABORCODE_AMCREWLABORAEID == "" ||
        !LABORCODE_AMCREWLABORAEID?.includes(
          TECHNICIANS[technical].LABORCODE
        )) &&
      TECHNICIANS[technical].LABORCODE
    ) {
      createAssociation.push({
        AMCREWID,
        LABORCODE: TECHNICIANS[technical].LABORCODE,
        AMCREWAVAILAEID,
        OPDATE,
      });
    }
  }

  for (const technical in currentTechnical) {
    const formatTechnical = currentTechnical[technical].split("_");
    if (
      formatTechnical[0] &&
      !TECHNICIANS.some(
        (technician) => technician.LABORCODE === formatTechnical[0]
      )
    ) {
      deleteAssociation.push({
        AMCREWLABORAEID: formatTechnical[1],
        LABORCODE: formatTechnical[0],
      });
    }
  }

  const respuestas = {
    finallyTechnicians: TECHNICIANS,
    msg: {},
    LABORCODE_AMCREWLABORAEID: currentTechnical,
    OPDATE: "",
    AMCREWAVAILAEID: "",
  };

  await Promise.all(
    deleteAssociation.map((e) =>
      fetchPostData(WWSS.user.crews.day_labor_code.delete, {
        AMCREWLABORAEID: e.AMCREWLABORAEID,
        LABORCODE: e.LABORCODE,
      })
    )
  ).then((res) => {
    res.forEach((respuesta, index) => {
      if (!respuesta.error) {
        respuestas.msg[
          `${deleteAssociation[index].LABORCODE} : ${nuxtApp.$i18n.t(
            "crew.messages.delete.successfulTechnical"
          )}`
        ] = respuesta;
        respuestas.LABORCODE_AMCREWLABORAEID =
          respuestas.LABORCODE_AMCREWLABORAEID.filter(
            (labor) => !labor.includes(respuesta.LABORCODE)
          );

        respuestas.finallyTechnicians = respuestas.finallyTechnicians.filter(
          (technical) => technical.LABORCODE != respuesta.LABORCODE
        );
        respuestas.OPDATE = respuesta.OPDATE;
        respuestas.AMCREWAVAILAEID = respuesta.AMCREWAVAILAEID;
      } else {
        respuestas.msg[deleteAssociation[index].LABORCODE] = respuesta;
      }
    });
  });

  await Promise.all(
    createAssociation.map((e) =>
      fetchPostData(WWSS.user.crews.day_labor_code.create, {
        AMCREWID: e.AMCREWID,
        LABORCODE: e.LABORCODE,
        AMCREWAVAILAEID: e.AMCREWAVAILAEID,
        OPDATE: e.OPDATE,
      })
    )
  ).then((res) => {
    res.forEach((respuesta, index) => {
      if (!respuesta.error) {
        respuestas.msg[
          `${createAssociation[index].LABORCODE} : ${nuxtApp.$i18n.t(
            "crew.messages.create.successfulTechnical"
          )}`
        ] = respuesta;

        // Solo añadir si no esta ya de antes
        if (
          !TECHNICIANS.some(
            (technician) => technician.LABORCODE === respuesta.LABORCODE
          )
        ) {
          respuestas.finallyTechnicians.push(respuesta);
        }
        respuestas.LABORCODE_AMCREWLABORAEID.push(
          `${respuesta.LABORCODE}_${respuesta.AMCREWLABORAEID}`
        );
        respuestas.OPDATE = respuesta.OPDATE;
        respuestas.AMCREWAVAILAEID = respuesta.AMCREWAVAILAEID;
      } else {
        // Si salio mal añadir y esta en techinicias tengo que borarrlo
        if (
          TECHNICIANS.some(
            (technician) =>
              technician.LABORCODE === createAssociation[index].LABORCODE
          )
        ) {
          respuestas.finallyTechnicians = respuestas.finallyTechnicians.filter(
            (technical) =>
              technical.LABORCODE != createAssociation[index].LABORCODE
          );
        }
        respuestas.msg[createAssociation[index].LABORCODE] = respuesta;
      }
    });
  });

  return respuestas;
};

export const getCrewDaysLaborCode = async (amcrewid, dateinit, dateend) => {
  const masters = useMasters();

  const parameters = {
    amcrewid,
    dateinit,
    dateend,
  };

  const data = await fetchPostData(
    WWSS.user.crews.get_crew_days_laborcode,
    parameters
  ).catch((reason) => {
    globalMessage.showGenericErrorMessage(
      nuxtApp.$i18n.t("errors.messages.backend.crews")
    );
    masters.setGlobalLoading(false);
  });

  if (data && data.error) {
    globalMessage.showGenericErrorMessage(
      nuxtApp.$i18n.t("errors.messages.backend.crews")
    );
    masters.setGlobalLoading(false);
  } else {
    return data;
  }
};

export const cleanAllCrewsFilters = (filter_crews = true) => {
  const globalCrews = useGlobalCrews();
  globalCrews.filters.name = "";
  globalCrews.filters.description = "";
  globalCrews.filters.day = "";
  globalCrews.filters.technicians = "";
  globalCrews.filters.type = [];
  globalCrews.filters.subcontractor = [];

  if (filter_crews) {
    filterCrews();
  }
};

export const filterCrews = () => {
  const globalCrews = useGlobalCrews();
  const masters = useMasters();
  globalCrews.crewsListado = masters.getAllCrews.filter((crew) => {
    const nameMatch =
      globalCrews.filters.name === "" ||
      globalCrews.filters.name === null ||
      crew.AMCREW.toLowerCase().includes(
        globalCrews.filters.name.toLowerCase()
      );
    const time = timeToDecimal(globalCrews.filters.day);
    const dayMatch =
      globalCrews.filters.day === "" ||
      globalCrews.filters.day === null ||
      parseFloat(time) === parseFloat(crew.HOURS);
    const descriptionMatch =
      globalCrews.filters.description === "" ||
      globalCrews.filters.description === null ||
      crew.DESCRIPTION.toLowerCase().includes(
        globalCrews.filters.description.toLowerCase()
      );
    const techniciansMatch =
      globalCrews.filters.technicians === "" ||
      globalCrews.filters.technicians === null ||
      parseInt(globalCrews.filters.technicians) === parseInt(crew.PERSONNUM);
    const typeMatch =
      globalCrews.filters.type.length === 0 ||
      globalCrews.filters.type.length === null ||
      (globalCrews.filters.type.length > 0 &&
        globalCrews.filters.type.includes(crew.AMCREWTYPE));
    const subcontractorMatch =
      globalCrews.filters.subcontractor.length === 0 ||
      globalCrews.filters.subcontractor.length === null ||
      (globalCrews.filters.subcontractor.length > 0 &&
        globalCrews.filters.subcontractor.includes(crew.CREWWORKGROUPAE));
    return (
      nameMatch &&
      dayMatch &&
      descriptionMatch &&
      techniciansMatch &&
      typeMatch &&
      subcontractorMatch
    );
  });
  globalCrews.setFilteredCrewsDayLaborcode(globalCrews.crewsListado);
};

export const getDefaultWorkTime = () => {
  return DEFAULT_CREW_HOURS * DEFAULT_CREW_WORKERS;
};

export const getSlotData = async (params) => {
  const slot = await fetchPostData(WWSS.user.crews.get_crew_day, params).catch(
    (reason) => {
      console.error(nuxtApp.$i18n.t("errors.messages.backend.slot"), reason);
    }
  );

  if (slot && slot.error) {
    console.error(nuxtApp.$i18n.t("errors.messages.backend.slot"), slot.error);
    return null;
  }
  return slot;
};
